import { createAsyncThunk } from '@reduxjs/toolkit';

import { createClientGraphQL } from '../../graphql/createClientGraphQL';
import { ResponseQueryBackOfficeUsers, QUERY_BACK_OFFICE_USERS } from '../../queries/list';
import { selectorProjectId } from '../selectors/general';

export const thunkBackOfficeUsers = createAsyncThunk<ResponseQueryBackOfficeUsers['getBackOfficeUsers'], void>(
  'appointees/list',
  async (_, thunkAPI) => {
    try {
      // @ts-ignore
      const project_id = selectorProjectId(thunkAPI.getState());
      const GraphQL = createClientGraphQL();
      const response = await GraphQL.query({ query: QUERY_BACK_OFFICE_USERS, variables: { project_id } });
      const { getBackOfficeUsers } = response.data as ResponseQueryBackOfficeUsers;
      return thunkAPI.fulfillWithValue(getBackOfficeUsers);
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue('Failed to load backOfficeUsers');
    }
  },
);
