import React, { useEffect } from 'react';

import { useMutation } from '@apollo/client';
import { useTranslation } from '@tecma/i18n';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Route, useHistory } from 'react-router-dom';

import convertLanguage from 'helpers/functions/convertLanguage';
import Client from 'helpers/queries/query.client';
import { useStore } from 'mobx/helpers';

import PageTemplate from 'components/Template.Page';

import { useNavbarRoutes } from '../hooks/useNavbarRoutes';

interface Props {
  exact?: boolean;
  path: string;
  ToRender: React.ElementType;
  title: string;
  goBack?: boolean;
}

const PrivateRoute = observer(({ exact = false, path, ToRender, title, goBack = false }: Props) => {
  const [updateUser] = useMutation(Client.UPDATE_USER);
  const { i18n, t } = useTranslation();
  const store = useStore();
  const [newLang, setNewLang] = React.useState('');
  const history = useHistory();
  const { pathname } = history.location;
  const navbarRoutes = useNavbarRoutes();

  const setLanguage = (lang, locale) => {
    setNewLang(lang);
    // @ts-ignore
    const input = { email: store.loggedUser.email, language: lang, locale: locale, project_ids: store.loggedUser.project_ids };
    // @ts-ignore
    const oldLanguage = store.loggedUser && store.loggedUser.language;
    // @ts-ignore
    const oldLocale = store.loggedUser && store.loggedUser.locale;
    // @ts-ignore
    updateUser(Client.UPDATE_USER_DEFAULT_OPTIONS(store.loggedUser.id, input))
      .then(() => {
        store.setSnackbar(true, t('snackbar.successOperation'));
        store.setSystemLanguage(lang);
        store.setLoggedUserLang(lang, locale);
        i18n.changeLanguage(convertLanguage(lang, locale));
      })
      .catch((e) => {
        if (oldLanguage) {
          setNewLang(oldLanguage);
          store.setLoggedUserLang(oldLanguage, oldLocale);
          store.setSystemLanguage(oldLanguage);
          i18n.changeLanguage(convertLanguage(oldLanguage, oldLocale));
        }
        store.setSnackbarError(true, t('snackbar.failedOperation'));
        console.error(e);
      });
  };

  const render = (props) => <PageTemplate BodyComponent={ToRender} title={title} goBack={goBack} setLanguage={setLanguage} {...props} />;

  useEffect(() => {
    moment.locale(newLang);
  }, [newLang]);

  useEffect(() => {
    const isClientDetailPage = pathname.includes('scheda-cliente');
    const isClientList = pathname.includes('/clienti');
    const isClientListLegacy = pathname.includes('/clienti-legacy');
    const isNavbarRoute = !!navbarRoutes.find((route) => pathname.includes(route.url));
    const isCalendarDetailPage = pathname.includes('/new-event');
    const isClientCreatePage = pathname.includes('/aggiungi-cliente');
    const isPertinenzaPage = pathname.includes('/scheda-pertinenza');
    const isApartmentDetailPage = pathname.includes('/scheda-appartamento');
    const isCalendarioDetailPage = pathname.includes('/dettagli-calendario');
    const isCalendarioUpdatePage = pathname.includes('/update-event');
    const isValidRoute = isNavbarRoute || isClientDetailPage || isClientList || isClientListLegacy;
    const isClientUpdatePage = pathname.includes('/modifica-cliente');
    const idClient = pathname.split('/modifica-cliente')[1];

    if (isCalendarDetailPage || isCalendarioDetailPage || isCalendarioUpdatePage) return history.replace('/calendario');
    if (isClientUpdatePage) return history.replace(`/scheda-cliente${idClient}`);
    if (isClientCreatePage) return history.replace('/clienti');
    if (isPertinenzaPage || isApartmentDetailPage) return history.replace('/appartamenti');
    else if (!isValidRoute) return history.push('/');
  }, []);

  return <Route exact={exact} path={path} render={render} />;
});

export default PrivateRoute;
