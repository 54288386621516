import React, { memo } from 'react';

import ClearIcon from '@material-ui/icons/Clear';
import { observer } from 'mobx-react';

import { useStore } from 'mobx/helpers';

import CrmSnackbar from 'components/Snackbar';

const CrmSnackbarError = observer(() => {
  const store = useStore();

  return (
    <CrmSnackbar open={store.snackbarError} onClose={() => store.setSnackbarError(false)}>
      <div className='snackbar-body-error'>
        <ClearIcon style={{ marginRight: '5px' }} />
        {store.snackbarMessage}
      </div>
    </CrmSnackbar>
  );
});

export default memo(CrmSnackbarError);
