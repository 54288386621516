import { createSelector } from '@reduxjs/toolkit';

import { TStore } from '../../../redux/hooks';

const selectorApp = (store: TStore) => store.app;
const selectorAdditionalInfoApp = (store: TStore) => store.additionalInfoApp;

export const selectorClients = createSelector(selectorApp, (app) => (app.clients ? JSON.parse(JSON.stringify(app.clients)) : []));
export const selectorLoading = createSelector(selectorApp, (app) => !!app.loading);
export const selectorProjectId = createSelector(selectorApp, (app) => app.projectId || '');
export const selectorFilterPage = createSelector(selectorApp, (app) => app.filters?.page || 1);
export const selectorFilterPerPage = createSelector(selectorApp, (app) => app.filters?.perPage || 10);
export const selectorFilterPrevPage = createSelector(selectorApp, (app) => app.filters?.prevPage);
export const selectorFilterNextPage = createSelector(selectorApp, (app) => app.filters?.nextPage);
export const selectorFilterTotalDocs = createSelector(selectorApp, (app) => app.filters?.totalDocs || 0);
export const selectorFilterTotalPages = createSelector(selectorApp, (app) => app.filters?.totalPages || 0);
export const selectorSortDirection = createSelector(selectorApp, (app) => app.sortOrder);
export const selectorSortField = createSelector(selectorApp, (app) => app.sortField);
export const selectorSelectedTab = createSelector(selectorApp, (app) => app.selectedTab);
export const selectorDrawerTagsUserId = createSelector(selectorApp, (app) => app.drawerTagsUserId);
export const selectorClientIdToRenewReservation = createSelector(selectorApp, (app) => app.clientIdToRenewReservation);
export const selectorLoadingDrawer = createSelector(selectorApp, (app) => !!app.loadingDrawer);
export const selectorLoadingAdditionalInfo = createSelector(selectorApp, (app) => !!app.loadingAdditionalInfo);
export const selectorAdditionalInfo = createSelector(selectorApp, (app) => app.additionalInfo || []);
export const selectorTags = createSelector(selectorAdditionalInfoApp, (additionalInfoApp) => additionalInfoApp?.tags || []);
export const selectorActions = createSelector(selectorAdditionalInfoApp, (additionalInfoApp) => additionalInfoApp.actions);
export const selectorOtherInfo = createSelector(selectorAdditionalInfoApp, (additionalInfoApp) => additionalInfoApp.otherInfo);
export const selectorAppointees = createSelector(selectorAdditionalInfoApp, (additionalInfoApp) => additionalInfoApp.appointees);
export const selectorLoadingTags = createSelector(selectorAdditionalInfoApp, (additionalInfoApp) => additionalInfoApp.loadingTags);
export const selectorLoadingActions = createSelector(selectorAdditionalInfoApp, (additionalInfoApp) => additionalInfoApp.loadingActions);
export const selectorLoadingOtherInfo = createSelector(
  selectorAdditionalInfoApp,
  (additionalInfoApp) => additionalInfoApp.loadingOtherInfo,
);
export const selectorLoadingAppointees = createSelector(
  selectorAdditionalInfoApp,
  (additionalInfoApp) => additionalInfoApp.loadingAppointees,
);
export const selectorSelectedFilters = createSelector(selectorAdditionalInfoApp, (additionalInfoApp) => additionalInfoApp.selectedFilters);
export const selectorActiveFilters = createSelector(selectorAdditionalInfoApp, (additionalInfoApp) => additionalInfoApp.activeFilters);
export const selectorActiveFiltersSearch = createSelector(selectorAdditionalInfoApp, (additionalInfoApp) => {
  const activeFilters = additionalInfoApp.activeFilters;
  const tags = activeFilters?.tags?.map((tag) => ({ key: tag.section, value: tag.subSections }));
  const actions = activeFilters?.actions?.map((action) => ({ key: action.section, value: action.subSections }));
  const otherInfo = activeFilters?.otherInfo?.map((otherInfo) => ({ key: otherInfo.key!, value: otherInfo.subSections }));
  const appointees = activeFilters?.appointees?.map((appointee) => appointee.id);
  const lastActions = activeFilters?.lastActions?.map((lastAction) => ({ key: lastAction.section, value: lastAction.subSections }));
  if (tags?.length === 0 && !actions && !otherInfo && !appointees) return {};

  return {
    tag: tags,
    actions,
    lastActions,
    otherInfo,
    appointee: appointees,
  };
});

export const selectorOtherInfoTags = createSelector([selectorAdditionalInfo, selectorProjectId], (additionalInfo, projectID) => {
  return additionalInfo.reduce(
    (acc, info) => {
      const isHiddenInProject = info.hiddenProjects?.includes(projectID);
      const isExcludedColumn = ['actions', 'tag2'].includes(info.name);
      const shouldIncludeColumn = (!isHiddenInProject || isHiddenInProject) && !isExcludedColumn;
      if (shouldIncludeColumn)
        acc[info.subSection] = [...(acc?.[info.subSection] ?? []), { name: info.name, path: info.path, label: info.label }];

      return acc;
    },
    {} as Record<string, Array<{ name: string; path: string; label: string }>>,
  );
});

export const selectorOtherInfoColumns = createSelector([selectorApp, selectorOtherInfoTags], (app) => {
  return app.columns || [];
});

export const selectorOtherAdditionalInfo = createSelector(selectorAdditionalInfo, (additionalInfo) => {
  return additionalInfo || [];
});
