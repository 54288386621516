import Cookies from 'js-cookie';

import { ENVIRONMENT } from '../constants/environment';

class UserService {
  constructor(projectBaseUrl) {
    const projectHost = projectBaseUrl.endsWith('/') ? projectBaseUrl.slice(0, -1) : projectBaseUrl;
    // @ts-ignore
    this.baseUserURL = `${projectHost}${ENVIRONMENT.REACT_APP_USER_API_BASE_URL}`;
    // @ts-ignore
    this.initRequest = {
      method: 'GET',
      referrerPolicy: 'unsafe-url',
      headers: { Authorization: `Bearer ${Cookies.get('jwt')}`, 'Content-Type': 'application/json' },
    };
  }

  async subscribeToNewsletterApi(
    userId,
    projectId,
    { projectHostKey, privacyPolicy, projectDefaultLang, subscription, projectArea = 'sell' },
  ) {
    // @ts-ignore
    const response = await fetch(`${this.baseUserURL}newsletter/${userId}/${projectId}`, {
      // @ts-ignore
      ...this.initRequest,
      method: 'POST',
      body: JSON.stringify({
        projectHostKey,
        projectArea,
        privacyPolicy,
        projectDefaultLang,
        subscription,
      }),
    });
    if (!response.ok) {
      throw new Error(`Status: ${response.status}`);
    }
    return response;
  }
}

export default UserService;
