import { ReactNode, useEffect } from 'react';

import CrmLoader from 'components/UI.Loader';

import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { sliceEnvironment } from '../redux/slice/slice.environment';
import { thunkEnvironment } from '../redux/thunks/thunk.environment';

interface Props {
  children: ReactNode;
}

export const ProviderEnvironment = ({ children }: Props) => {
  const environment = useAppSelector(sliceEnvironment.selectors.environment);
  const dispatch = useAppDispatch();

  const getEnvironment = async () => {
    dispatch(thunkEnvironment()).unwrap();
  };
  useEffect(() => {
    getEnvironment();
  }, []);

  if (!environment.REACT_APP_APOLLO_URI) return <CrmLoader hasBackdrop={false} loading z={true} />;

  return <>{children}</>;
};
