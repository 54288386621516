import { ReactNode, useEffect } from 'react';

import { datadogRum } from '@datadog/browser-rum';

import inizializeAnalytics from 'helpers/functions/analytics';

import packageJSON from '../../package.json';
import { ENVIRONMENT } from '../constants/environment';

interface Props {
  children: ReactNode;
}

export const ProviderAnalytics = ({ children }: Props) => {
  useEffect(() => {
    if (ENVIRONMENT.REACT_APP_GOOGLE_TAG_MANAGER_CODE) {
      inizializeAnalytics({
        gtmId: ENVIRONMENT.REACT_APP_GOOGLE_TAG_MANAGER_CODE,
      });
      console.log('Google Tag Manager initialized', ENVIRONMENT.REACT_APP_GOOGLE_TAG_MANAGER_CODE);
    }
    if (ENVIRONMENT.REACT_APP_NAMESPACE === 'biz-tecma-prod') {
      datadogRum.init({
        applicationId: ENVIRONMENT.REACT_APP_DATADOG_APPLICATION_ID || '',
        clientToken: ENVIRONMENT.REACT_APP_DATADOG_CLIENT_TOKEN || '',
        site: 'datadoghq.eu',
        service: 'followup-sell',
        env: ENVIRONMENT.REACT_APP_NAMESPACE,
        version: packageJSON.version,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
      });
      console.log('Datadog initialized', ENVIRONMENT.REACT_APP_DATADOG_APPLICATION_ID);
    }
  }, []);

  return <>{children}</>;
};
