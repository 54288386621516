import { createSlice } from '@reduxjs/toolkit';

import { ActionDetail } from '../../queries/list';
import { thunkActionDetail } from '../thunks/thunk.crm.actions';

interface ActionDetailState {
  data: ActionDetail | null;
  loading: boolean;
  error: string | null;
}

const initialState: ActionDetailState = {
  data: null,
  loading: false,
  error: null,
};

export const sliceActionDetail = createSlice({
  name: 'actionDetail',
  initialState,
  reducers: {
    clearActionDetail: (state) => {
      state.data = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunkActionDetail.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.data = null;
      })
      .addCase(thunkActionDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(thunkActionDetail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { clearActionDetail } = sliceActionDetail.actions;
export default sliceActionDetail.reducer;
