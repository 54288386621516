import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { createClientGraphQL } from '../../graphql/createClientGraphQL';
import { selectorProjectId } from '../selectors/general';

// #region ::: GRAPHQL
const query = gql`
  query getAllAdditionalInfoByProject($project_id: ID!) {
    getAllAdditionalInfoByProject(project_id: $project_id) {
      id
      type
      label
      options
      subOptions {
        section
        subSections
        color
      }
      hideInClientInfo
      disabled
      dimension
      required
      defaultValue
      multiple
      position
      path
      name
      tab
      subSection
      project_id
      hiddenProjects
      helperText
      period
      min
      max
      minLength
      maxLength
      step
    }
  }
`;
// #endregion

export const thunkAdditionalInfo = createAsyncThunk('additional-info', async (_, thunkAPI) => {
  try {
    // @ts-ignore
    const project_id = selectorProjectId(thunkAPI.getState());
    const GraphQL = createClientGraphQL();
    const response = await GraphQL.query({ query, variables: { project_id } });
    const { getAllAdditionalInfoByProject } = response.data;
    return thunkAPI.fulfillWithValue(getAllAdditionalInfoByProject);
  } catch (error) {
    console.error(error);
    return thunkAPI.rejectWithValue('Failed to load client list');
  }
});
