import { createAsyncThunk } from '@reduxjs/toolkit';

import { getValidFilters } from '@module-clients/helpers/get-valid-filters';

import { ParamsOnSearch, ActiveFilters, DateRange } from '../../declarations/common';
import { createClientGraphQL } from '../../graphql/createClientGraphQL';
import { ResponseQueryClientList, QUERY_CLIENT_LIST } from '../../queries/list';

export const thunkClientList = createAsyncThunk<
  ResponseQueryClientList['getCustomersTabInfo'],
  { project_id: string; params: ParamsOnSearch; filters: ActiveFilters; dateRanges: DateRange[] }
>('client/list', async ({ project_id, params, filters, dateRanges }, thunkAPI) => {
  try {
    const GraphQL = createClientGraphQL();
    const validFilters = getValidFilters(filters, dateRanges);
    const response = await GraphQL.query({
      query: QUERY_CLIENT_LIST,
      variables: { project_id, params, ...(Object.keys(validFilters).length > 0 ? { filters: validFilters } : null) },
    });
    const { getCustomersTabInfo } = response.data as ResponseQueryClientList;
    return thunkAPI.fulfillWithValue(getCustomersTabInfo);
  } catch (error) {
    console.error(error);
    return thunkAPI.rejectWithValue('Failed to load client list');
  }
});
