import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MenuIcon from '@material-ui/icons/Menu';
import { LanguageSelector } from '@tecma/ds';
import { observer } from 'mobx-react';

import { useStore } from 'mobx/helpers';

import CrmAvatar from 'components/UI.Avatar.Base';
import CrmSvgLoader from 'components/UI.SvgLoader';

import LogoTecma from 'images/assets/logo_TECMA_white.svg';

import 'styles/header.scss';

interface Props {
  setLanguage: (language: string, locale: string) => void;
}

const CrmHeader = observer(({ setLanguage }: Props) => {
  const store = useStore();

  const openDrawer = () => {
    store.setOpenDrawer(!store.openDrawer);
  };

  const handleChangeLanguage = (selectedLanguage) => {
    const trueLanguage = selectedLanguage.split('-')[0];
    const locale = selectedLanguage.split('-')[1];
    setLanguage(trueLanguage, locale);
  };

  const theme = useTheme();
  const matchesPhone = useMediaQuery(theme.breakpoints.down('xs'), {
    noSsr: true,
  });

  return (
    <div className='header-wrapper'>
      <div className='tecma-elements'>
        <div className='image-tecma-wrapper'>
          <CrmSvgLoader data={LogoTecma} alt='Tecma' className='image-tecma' />
        </div>
        {!matchesPhone && <div className='crm-wrapper'>FOLLOW UP</div>}
      </div>
      <div className='desktop-elements'>
        <CrmAvatar />
        {!matchesPhone ? (
          <LanguageSelector
            languages={store.configLanguages}
            // @ts-ignore
            currentLanguage={store.getCurrentLanguage()}
            onChangeLanguage={handleChangeLanguage}
            fixed={true}
          />
        ) : (
          <IconButton aria-label='more' aria-controls='long-menu' aria-haspopup='true' onClick={openDrawer} className='mobile-drawer'>
            <MenuIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
});

export default CrmHeader;
