import { createSlice } from '@reduxjs/toolkit';

import { TimeFrame } from '@module-clients/declarations/common';

export const sliceTimeFrame = createSlice({
  initialState: {} as {
    timeFrames?: TimeFrame[];
    activeTimeFrames?: TimeFrame[];
  },
  name: 'timeFrame',
  reducers: {
    setTimeFrames: (state, action) => {
      state.timeFrames = action.payload;
    },
    setActiveTimeFrames: (state, action) => {
      state.activeTimeFrames = action.payload;
    },
  },
  selectors: {
    timeFrames: (state) => state.timeFrames ?? [{ fromDate: '', toDate: '' }],
    dateRanges: (state) =>
      state.activeTimeFrames?.map((timeFrame) => ({
        field: timeFrame.field ?? '',
        //@ts-ignore
        fromDate: timeFrame.fromDate && typeof timeFrame.fromDate === 'object' ? timeFrame.fromDate.toISO() : timeFrame.fromDate,
        //@ts-ignore
        toDate: timeFrame.toDate && typeof timeFrame.toDate === 'object' ? timeFrame.toDate.toISO() : timeFrame.toDate,
      })) ?? [{ field: '', fromDate: '', toDate: '' }],
  },
});
