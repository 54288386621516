import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { ENVIRONMENT } from '../../constants/environment';
import { getConfig } from '../helpers/set-config';

export const createClientGraphQL = () => {
  const token = getConfig().token;
  const httpLink = from([
    new HttpLink({
      credentials: 'include',
      uri: ENVIRONMENT.REACT_APP_APOLLO_URI,
    }),
  ]);

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    };
  });

  return new ApolloClient({
    link: authLink.concat(httpLink),
    credentials: 'include',
    cache: new InMemoryCache({
      addTypename: false,
    }),
  });
};
