import { createAsyncThunk } from '@reduxjs/toolkit';

import { createClientGraphQL } from '../../graphql/createClientGraphQL';
import { ResponseMutationUpdateClientTags, MUTATION_UPDATE_CLIENT_TAGS } from '../../queries/list';
import { selectorProjectId } from '../selectors/general';

export const thunkUpdateClientTags = createAsyncThunk<ResponseMutationUpdateClientTags['updateClientTags'], { id: string; tags: string[] }>(
  'updateClientTags',
  async ({ id, tags }, thunkAPI) => {
    try {
      // @ts-ignore
      const project_id = selectorProjectId(thunkAPI.getState());
      const GraphQL = createClientGraphQL();
      const response = await GraphQL.mutate({ mutation: MUTATION_UPDATE_CLIENT_TAGS, variables: { project_id, id, tags } });
      const { updateClientTags } = response.data as ResponseMutationUpdateClientTags;
      return thunkAPI.fulfillWithValue(updateClientTags);
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue('Failed to update client tags');
    }
  },
);
