import { createAsyncThunk } from '@reduxjs/toolkit';

import { getValidFilters } from '@module-clients/helpers/get-valid-filters';

import { ParamsOnSearch, ActiveFilters, DateRange } from '../../declarations/common';
import { createClientGraphQL } from '../../graphql/createClientGraphQL';
import { ResponseQueryClientListCRM, QUERY_CLIENT_LIST_CRM } from '../../queries/list';

export const thunkClientListCRM = createAsyncThunk<
  ResponseQueryClientListCRM['getCRMTabInfo'],
  { project_id: string; params: ParamsOnSearch; filters: ActiveFilters; dateRanges: DateRange[] }
>('clientCRM/list', async ({ project_id, params, filters, dateRanges }, thunkAPI) => {
  try {
    const GraphQL = createClientGraphQL();
    const validFilters = getValidFilters(filters, dateRanges);
    const response = await GraphQL.query({
      query: QUERY_CLIENT_LIST_CRM,
      variables: { project_id, params, ...(Object.keys(validFilters).length > 0 ? { filters: validFilters } : null) },
    });
    const { getCRMTabInfo } = response.data as ResponseQueryClientListCRM;
    return thunkAPI.fulfillWithValue(getCRMTabInfo);
  } catch (error) {
    console.error(error);
    return thunkAPI.rejectWithValue('Failed to load CRM client list');
  }
});
