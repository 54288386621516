import { ReactNode, useEffect } from 'react';

import MomentUtils from '@date-io/moment';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { ProviderMobx, store } from 'mobx/helpers';
import { CrmMUITheme } from 'styles/crmMUITheme';

import { ProviderAnalytics } from './Provider.Analytics';
import { ProviderEnvironment } from './Provider.Environment';
import { ProviderGraphQL } from './Provider.GraphQL';
import { ProviderI18n } from './Provider.i18n';
import { ReduxProvider } from '../redux/Provider';

// @ts-ignore
const theme = createTheme(CrmMUITheme);

interface Props {
  children: ReactNode;
}

export const Providers = ({ children }: Props) => {
  useEffect(() => {
    // @ts-ignore
    document.title = store?.pageTitles?.followup ?? 'Follow Up';
  }, []);

  return (
    <ReduxProvider>
      <ProviderEnvironment>
        <ProviderI18n>
          <ProviderAnalytics>
            <ProviderMobx value={store}>
              <ProviderGraphQL>
                <ThemeProvider theme={theme}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>{children}</MuiPickersUtilsProvider>
                </ThemeProvider>
              </ProviderGraphQL>
            </ProviderMobx>
          </ProviderAnalytics>
        </ProviderI18n>
      </ProviderEnvironment>
    </ReduxProvider>
  );
};
