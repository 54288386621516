import { createAsyncThunk } from '@reduxjs/toolkit';

import { setEnvironment } from '../../constants/environment';
import { utilityGetBaseName } from '../../helpers/functions/get-base-name';

export const thunkEnvironment = createAsyncThunk('environment/thunk', async (_, thunkAPI) => {
  try {
    const response = await fetch(`${utilityGetBaseName()}config.json`);
    const config = await response.json();
    setEnvironment(config);
    return thunkAPI.fulfillWithValue(config);
  } catch (error) {
    return thunkAPI.rejectWithValue('Failed to load environment');
  }
});
