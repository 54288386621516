import { createSlice } from '@reduxjs/toolkit';

import { Client, ClientCRM, ClientMyHome, TableTab, AdditionalInfoTag } from '../../declarations/common';
import { thunkAdditionalInfo } from '../thunks/thunk.additional-info';
import { thunkClientList } from '../thunks/thunk.client.list';
import { thunkClientListCRM } from '../thunks/thunk.client.list.crm';
import { thunkClientListMyHome } from '../thunks/thunk.client.list.my-home';
import { thunkClientListOtherInfo } from '../thunks/thunk.client.list.other-info';
import { thunkUpdateClientTags } from '../thunks/thunk.client.tags.update';

export const sliceClienti = createSlice({
  initialState: {} as {
    clients: Array<Client | ClientMyHome | ClientCRM>;
    loading?: boolean;
    loadingDrawer?: boolean;
    projectId?: string;
    columns?: Array<AdditionalInfoTag>;
    filters?: {
      totalDocs: number;
      totalPages: number;
      page: number;
      perPage: number;
      prevPage: number | null;
      nextPage: number | null;
    };
    sortOrder?: 1 | -1;
    sortField?: 'CREATED_ON' | 'UPDATED_ON' | 'EMAIL' | 'FULL_NAME' | 'FIRST_NAME' | 'LAST_NAME' | 'PHONE';
    selectedTab: TableTab;
    drawerTagsUserId?: string;
    loadingAdditionalInfo?: boolean;
    additionalInfo?: Array<AdditionalInfoTag>;
    clientIdToRenewReservation?: string;
  },
  name: 'clients',
  reducers: {
    setPage: (state, action) => {
      if (state.filters) state.filters.page = action.payload;
    },
    setColumns: (state, action) => {
      state.columns = action.payload;
    },
    setProjectID: (state, action) => {
      state.projectId = action.payload;
    },
    setPageSize: (state, action) => {
      if (state.filters) state.filters.perPage = action.payload;
    },
    setSortDirection: (state, action) => {
      state.sortOrder = action.payload;
    },
    setSortField: (state, action) => {
      state.sortField = action.payload;
    },
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
    setDrawerTagsUserId: (state, action) => {
      state.drawerTagsUserId = action.payload;
    },
    setClientIdToRenewReservation: (state, action) => {
      state.clientIdToRenewReservation = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Contacts tab
    builder.addCase(thunkClientList.pending, (state, action) => {
      state.projectId = action.meta.arg.project_id;
      state.loading = true;
    });
    builder.addCase(thunkClientList.fulfilled, (state, action) => {
      delete state.loading;
      state.clients = action.payload.data;
      state.filters = action.payload.paginationInfo;
    });
    builder.addCase(thunkClientList.rejected, (state) => {
      delete state.loading;
    });
    // MyHome tab
    builder.addCase(thunkClientListMyHome.pending, (state, action) => {
      state.projectId = action.meta.arg.project_id;
      state.loading = true;
    });
    builder.addCase(thunkClientListMyHome.fulfilled, (state, action) => {
      delete state.loading;
      state.clients = action.payload.data;
      state.filters = action.payload.paginationInfo;
    });
    builder.addCase(thunkClientListMyHome.rejected, (state) => {
      delete state.loading;
    });
    // CRM tab
    builder.addCase(thunkClientListCRM.pending, (state, action) => {
      state.projectId = action.meta.arg.project_id;
      state.loading = true;
    });
    builder.addCase(thunkClientListCRM.fulfilled, (state, action) => {
      delete state.loading;
      state.clients = action.payload.data;
      state.filters = action.payload.paginationInfo;
    });
    builder.addCase(thunkClientListCRM.rejected, (state) => {
      delete state.loading;
    });
    // OTHER INFO tab
    builder.addCase(thunkClientListOtherInfo.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(thunkClientListOtherInfo.fulfilled, (state, action) => {
      delete state.loading;
      state.clients = action.payload.data;
      state.filters = action.payload.paginationInfo;
    });
    builder.addCase(thunkClientListOtherInfo.rejected, (state) => {
      delete state.loading;
    });
    // ADDITIONAL INFO
    builder.addCase(thunkAdditionalInfo.pending, (state, action) => {
      state.loadingAdditionalInfo = true;
    });
    builder.addCase(thunkAdditionalInfo.fulfilled, (state, action) => {
      state.additionalInfo = action.payload;
      delete state.loadingAdditionalInfo;
    });
    builder.addCase(thunkAdditionalInfo.rejected, (state) => {
      delete state.additionalInfo;
      delete state.loadingAdditionalInfo;
    });
    // Update client tags
    builder.addCase(thunkUpdateClientTags.pending, (state, action) => {
      state.loadingDrawer = true;
    });
    builder.addCase(thunkUpdateClientTags.fulfilled, (state, action) => {
      delete state.loadingDrawer;
    });
    builder.addCase(thunkUpdateClientTags.rejected, (state) => {
      delete state.loadingDrawer;
    });
  },
});
