import React from 'react';

import { Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import 'styles/input.scss';

const CrmInputRadioButton = (props) => {
  const { extraClass, label, required, disabled, options, onChange, value, canResetChoice, defaultValue } = props;
  const newValue = value !== null ? (value === '' ? defaultValue : typeof value === 'object' ? value.value : value) : null;

  const [check, setCheck] = React.useState(newValue);

  const myOnClick = (value) => {
    if (value === check && canResetChoice) {
      setCheck('');
    } else {
      setCheck(value);
    }
  };

  return (
    <div className='MuiFormControl-root MuiTextField-root input-text'>
      <label className='MuiFormLabel-root MuiInputLabel-root medium smaller-font'>
        {check ? <span style={{ fontWeight: '700' }}>{label}</span> : <span>{label}</span>}
      </label>
      <RadioGroup row aria-label={label} name={label} value={newValue} onChange={onChange} className={`input-text ${extraClass}`}>
        {options.map((el) => {
          return (
            <FormControlLabel
              key={el.value}
              value={el.value}
              control={
                <Radio
                  required={required}
                  checked={check === el.value}
                  disableRipple={true}
                  onClick={() => {
                    myOnClick(el.value);
                  }}
                />
              }
              label={
                (check === el.value) !== null && el.value !== undefined ? (
                  <span style={{ fontWeight: '700' }}>{el.label}</span>
                ) : (
                  <span>{el.label}</span>
                )
              }
              disabled={disabled}
            />
          );
        })}
      </RadioGroup>
    </div>
  );
};

CrmInputRadioButton.defaultProps = {
  extraClass: '',
  label: '',
  value: '',
  required: false,
  disabled: false,
  canResetChoice: true,
  defaultValue: '',
};

CrmInputRadioButton.propTypes = {
  extraClass: PropTypes.string,
  label: PropTypes.string,
  canResetChoice: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default CrmInputRadioButton;
