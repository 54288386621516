import { memo, useEffect } from 'react';

import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { useStore } from 'mobx/helpers';

const LogoutListener = observer(() => {
  const store = useStore();
  const history = useHistory();

  const clearStore = () => {
    try {
      sessionStorage.removeItem('searchText');
      sessionStorage.removeItem('tableSelectedTab');
      sessionStorage.removeItem('activeFilters');
      sessionStorage.removeItem('activeTimeFrames');
      sessionStorage.removeItem('tags');
      sessionStorage.removeItem('columns');
      store.logOut();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (store.redirectionToSsoNeeded) {
      clearStore();
      history.push('/login');
    }
  }, [store.redirectionToSsoNeeded]);

  return null;
});

export default memo(LogoutListener);
