import React from 'react';

import ReactDOM from 'react-dom';

import { App } from 'App';

import { ErrorBoundary } from './components/App.Error-Bounday';
import { Providers } from './components/App.Providers';
import * as serviceWorker from './serviceWorker';

import 'styles/main.scss';

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </Providers>
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorker.register();
