import { useEffect, useState, lazy } from 'react';

import { useLazyQuery, useQuery } from '@apollo/client';
import { setBaseUrl } from '@tecma/bucket-lib';
import { loadResources, useTranslation } from '@tecma/i18n';
import moment from 'moment';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import convertLanguage from 'helpers/functions/convertLanguage';
import DetectUrl from 'helpers/functions/DetectUrl';
import JwtChecker from 'helpers/functions/JwtChecker.js';
import Client from 'helpers/queries/query.client';
import { useStore } from 'mobx/helpers';

import LogoutListener from 'components/Auth.Logout';
import PageLogin from 'components/Page.Login';
import PrivateRoute from 'components/Route.Logged';
import SessionStore from 'components/Session';
import CrmSnackbarAmber from 'components/Snackbar.Amber';
import CrmSnackbarError from 'components/Snackbar.Error';
import CrmSnackbarFeedback from 'components/Snackbar.Feedback';
import CrmLoader from 'components/UI.Loader';

import { useAppDispatch } from './redux/hooks';

const PageApartmentCreate = lazy(() => import('components/Page.Apartment.Create'));
const PageApartmentList = lazy(() => import('components/Page.Apartment.List'));
const PageApparmentUpdate = lazy(() => import('components/Page.Apartment.Update'));
const AggiornaConfermaPassword = lazy(() => import('components/Page.Auth.Password.Confirm'));
const PageCalendar = lazy(() => import('components/Page.Calendar'));
const PageCalendarDetail = lazy(() => import('components/Page.Calendar.Detail'));
const PageClientCreate = lazy(() => import('components/Page.Client.Create'));
const PageClientDetail = lazy(() => import('components/Page.Client.Detail'));
const PageClientUpdate = lazy(() => import('components/Page.Client.Update'));
const PageClientListLegacy = lazy(() => import('components/Page.Clienti'));
const PageClients = lazy(() => import('components/Page.Clienti.V2'));
const PageDocuments = lazy(() => import('components/Page.Documents'));
const PageEventCreate = lazy(() => import('components/Page.Event.Create'));
const PageEventUpdate = lazy(() => import('components/Page.Event.Update'));
const PageHome = lazy(() => import('components/Page.Home'));
const PagePertinenceUpdate = lazy(() => import('components/Page.Pertinence.Update'));
const PageProposalManagement = lazy(() => import('components/Page.Proposal.Management'));
const PageQuoteList = lazy(() => import('components/Page.Quotes'));
const PageSlotUpdate = lazy(() => import('components/Page.Slot.Update'));
const PageUserDetail = lazy(() => import('components/Page.User.Detail'));
const PageUserManagement = lazy(() => import('components/Page.User.Management'));

export const App = () => {
  const store = useStore();
  const [loading, setLoading] = useState(true);
  const [i18nLoading, seti18nLoading] = useState(true);
  const { i18n } = useTranslation();
  const [jwtLoading, setJwtLoading] = useState(true);
  const prjInfo = useQuery(Client.GET_PROJECT_INFO, Client.GET_PROJECT_INFO_DEFAULT_OPTIONS(DetectUrl()));
  const skipCondition = window.location.pathname.includes('resetPassword');
  const [loadUserInfo, userInfo] = useLazyQuery(Client.GET_USER_INFO);
  const dispatch = useAppDispatch();

  const getProject = async () => {
    if (prjInfo && prjInfo.data && prjInfo.data.getProjectInfoByHost) {
      const project = { ...prjInfo.data.getProjectInfoByHost };
      store.setAssetsByObject(project);
      dispatch({ type: 'SET_PROJECT', payload: project });
      store.setDefaultLanguage(project.defaultLang);
      dispatch({ type: 'SET_LANGUAGES', payload: project.languages });
      setBaseUrl(prjInfo.data.getProjectInfoByHost.baseurl);
      dispatch({ type: 'SET_BASE_URL', payload: store.baseUrl });
      // @ts-ignore
      document.title = store.pageTitles?.followup;
      setLoading(false);
      await loadResources({
        id: project.id,
        displayName: project.displayName,
        languages: store.configLanguages,
      });
      seti18nLoading(false);
    }
  };

  useEffect(() => {
    getProject();
  }, [prjInfo]);

  useEffect(() => {
    if (userInfo && userInfo.data && userInfo.data.getUserByJWT) {
      setJwtLoading(false);
      const userLanguage = userInfo?.data?.getUserByJWT?.language;
      const userLocale = userInfo?.data?.getUserByJWT?.locale;
      store.setLoggedUserJwtData({ userData: userInfo.data.getUserByJWT, checkingJwt: false });
      dispatch({ type: 'SET_USER', payload: userInfo.data.getUserByJWT });
      store.setSystemLanguage(userLanguage);
      dispatch({ type: 'SET_SYSTEM_LANGUAGE', payload: userLanguage });
      // @ts-ignore
      if (i18n.language.split('-')[0] !== (store.loggedUser.language || userLanguage) && userLanguage) {
        const convertedLanguage = convertLanguage(userLanguage, userLocale);
        i18n.changeLanguage(convertedLanguage);
        moment.locale(convertedLanguage);
      }
    } else if (userInfo?.error) {
      setJwtLoading(false);
    }
  }, [userInfo]);

  useEffect(() => {
    store.setCheckingJwt(true);
    if (store.projectId && !userInfo.called && !skipCondition)
      loadUserInfo(Client.GET_USER_INFO_DEFAULT_OPTIONS(store.projectId, skipCondition));
  }, [store.projectId]);

  if (loading || i18nLoading) return <CrmLoader hasBackdrop={false} loading z />;

  if (!store.baseUrl || !prjInfo)
    return (
      <div style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <h1>Iniziativa non valida</h1>
      </div>
    );

  return (
    <div className='wrapper'>
      <JwtChecker />
      {jwtLoading ? (
        <CrmLoader hasBackdrop={false} loading={true} z={true} />
      ) : (
        <SessionStore>
          <Router basename={store.baseUrl}>
            <CrmSnackbarFeedback />
            <CrmSnackbarAmber />
            <CrmSnackbarError />
            <Switch>
              <PrivateRoute exact path='/' ToRender={PageHome} title='title.home' />
              <PrivateRoute exact path='/clienti' ToRender={PageClients} title='title.clientManager' />
              <PrivateRoute path='/scheda-cliente/:id' ToRender={PageClientDetail} title='title.clientCard' goBack />
              <PrivateRoute path='/modifica-cliente/:id' ToRender={PageClientUpdate} title='title.updateClientCard' goBack />
              <PrivateRoute exact path='/aggiungi-cliente' ToRender={PageClientCreate} title='title.addClient' goBack />
              <PrivateRoute exact path='/clienti-legacy' ToRender={PageClientListLegacy} title='title.clientManager' />
              <PrivateRoute exact path='/appartamenti' ToRender={PageApartmentList} title='title.apartments' />
              <PrivateRoute exact path='/scheda-appartamento/:id' ToRender={PageApparmentUpdate} title='title.apartmentCard' goBack />
              <PrivateRoute exact path='/crea-appartamenti' ToRender={PageApartmentCreate} title='title.createtList' goBack />
              <PrivateRoute exact path='/userInfoManager' ToRender={PageUserDetail} title='title.userInfoManager' />
              <PrivateRoute exact path='/calendario' ToRender={PageCalendar} title='title.calendar' />
              <PrivateRoute exact path='/dettagli-calendario/:id' ToRender={PageCalendarDetail} title='title.appointmentDetails' goBack />
              <PrivateRoute path='/new-event/:id' ToRender={PageEventCreate} title='title.createAppointment' />
              <PrivateRoute path='/update-event/:id' ToRender={PageEventUpdate} title='title.updateAppointment' goBack />
              <PrivateRoute exact path='/accountManager' ToRender={PageUserManagement} title='title.vendorManager' />
              <PrivateRoute exact path='/preventivi' ToRender={PageQuoteList} title='title.listaPreventivi' />
              <PrivateRoute path='/scheda-pertinenza/:id' ToRender={PagePertinenceUpdate} title='title.pertinenzaCard' goBack />
              <PrivateRoute path='/update-busy-slot/:id' ToRender={PageSlotUpdate} title='title.updateSlot' goBack />
              <PrivateRoute exact path='/gestioneProposta' ToRender={PageProposalManagement} title='title.updateSlot' />
              <PrivateRoute exact path='/documenti-hc' ToRender={PageDocuments} title='navbarTitle.documentiHC' />
              <Route exact path='/resetPassword' component={AggiornaConfermaPassword} />
              <Route exact path='/login' component={PageLogin} />
              <Route path='*' component={() => <Redirect to='/' />} />
            </Switch>
            <LogoutListener />
          </Router>
        </SessionStore>
      )}
    </div>
  );
};
