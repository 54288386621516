export let ENVIRONMENT = {
  REACT_APP_APOLLO_URI: '',
  REACT_APP_BUCKET_BASEURL: '',
  REACT_APP_USER_API_BASE_URL: '',
  REACT_APP_CALENDAR_API_BASE_URL: '',
  REACT_APP_HOST: '',
  REACT_APP_TRANSLATION_URL: '',
  REACT_APP_TIMEOUT_TOKEN: 120000,
  REACT_APP_CIRO_LOCK_ENDPOINT: '',
  REACT_APP_CIRO_LOCK_ENDPOINT_KEY: '',
  REACT_APP_FEATURE_FLAG_CLIENTS_V2: false,
  REACT_APP_DATADOG_APPLICATION_ID: '',
  REACT_APP_DATADOG_CLIENT_TOKEN: '',
  REACT_APP_NAMESPACE: '',
  REACT_APP_API_URI: '',
  REACT_APP_GOOGLE_TAG_MANAGER_CODE: '',
  REACT_APP_USE_BUCKET_PREFIX: '',
};

export const setEnvironment = (env: any) => {
  ENVIRONMENT = env;
};
