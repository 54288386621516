import { createSlice } from '@reduxjs/toolkit';

import type { Snackbar } from '@module-clients/declarations/common';

export const sliceDialogs = createSlice({
  initialState: {} as {
    snackbar: Snackbar | null;
  },
  name: 'dialogs',
  reducers: {
    setSnackbar: (state, action) => {
      state.snackbar = action.payload;
    },
    clearSnackbar: (state) => {
      state.snackbar = null;
    },
  },
  selectors: {
    snackbar: (state) => state.snackbar,
  },
});
