import { ActiveFilters, DateRange } from '@module-clients/declarations/common';

const getValidDateRanges = (dateRanges: DateRange[]) => {
  const filtered = dateRanges?.filter((e) => !!e.field && !!e.fromDate && !!e.toDate);
  return filtered?.length > 0 ? filtered : null;
};

export const getValidFilters = (filters: ActiveFilters, dateRanges: DateRange[]) => {
  const validDateRanges = getValidDateRanges(dateRanges);
  let validFilters: ActiveFilters & { dateRanges?: DateRange[] } = {};
  if (Object.keys(filters).length > 0) validFilters = filters;
  if (validDateRanges) validFilters.dateRanges = validDateRanges;

  return validFilters;
};
