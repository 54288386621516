import React, { memo } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Portal from '@material-ui/core/Portal';
import { Trans } from '@tecma/i18n';

import CrmButton from 'components/UI.Button.Base';

import colors from '../styles/colors.module.scss';
import 'styles/modal.scss';

interface Props {
  extraContentClasses?: string;
  extraClassesTitle?: string;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  dialogClasses?: object;
  title: string | object;
  disablePrimary?: boolean;
  open: boolean;
  buttonLabel: string | object;
  cancelLabel: string | object;
  onClose: () => void;
  actionToDo: () => void;
  cancelAction?: () => void;
  children: React.ReactNode;
  colorActionButton?: string;
  testIdActionButton?: string;
  titleColor?: string;
  customConfirmButton?: React.ReactNode;
  paperOverrideClasses?: object;
  spacingBetweenContentAndButtons?: object;
  cancelButtonExtraClass?: string;
}

const CrmSimpleModal = ({
  disablePrimary,
  title,
  children,
  onClose,
  dialogClasses,
  extraContentClasses,
  actionToDo,
  buttonLabel,
  cancelLabel,
  cancelAction,
  cancelButtonExtraClass,
  open,
  maxWidth,
  colorActionButton,
  extraClassesTitle,
  testIdActionButton,
  customConfirmButton,
  paperOverrideClasses,
  spacingBetweenContentAndButtons,
  titleColor,
}: Props) => {
  const action = () => {
    actionToDo();
    onClose();
  };

  const cancel = () => {
    if (cancelAction) cancelAction();
    onClose();
  };

  return (
    <Portal>
      <Dialog
        maxWidth={maxWidth}
        open={open}
        classes={dialogClasses}
        onClose={onClose}
        PaperProps={paperOverrideClasses ? paperOverrideClasses : {}}
      >
        <DialogTitle
          classes={{
            root: `modal-title light ${extraClassesTitle}`,
          }}
          disableTypography
          style={titleColor ? { color: `${titleColor}` } : {}}
        >
          {title}
        </DialogTitle>
        <DialogContent
          classes={{
            root: `modal-content ${extraContentClasses}`,
          }}
        >
          {children}
        </DialogContent>
        <DialogActions classes={{ root: 'modal-actions' }} style={spacingBetweenContentAndButtons ? spacingBetweenContentAndButtons : {}}>
          {cancelAction && (
            <CrmButton onClick={cancel} className={cancelButtonExtraClass ? cancelButtonExtraClass : 'modal-cancel-button'}>
              {cancelLabel}
            </CrmButton>
          )}
          {customConfirmButton ? (
            customConfirmButton
          ) : (
            <CrmButton
              onClick={action}
              disabled={disablePrimary}
              className='modal-action-button'
              data-testid={testIdActionButton}
              style={{ backgroundColor: `${colorActionButton}` }}
            >
              {buttonLabel}
            </CrmButton>
          )}
        </DialogActions>
      </Dialog>
    </Portal>
  );
};

CrmSimpleModal.defaultProps = {
  extraContentClasses: '',
  extraClassesTitle: '',
  maxWidth: 'sm',
  dialogClasses: {},
  title: '',
  disablePrimary: false,
  open: false,
  buttonLabel: 'Ok',
  cancelLabel: <Trans i18nKey={'general.back'} />,
  onClose: () => {},
  actionToDo: () => {},
  colorActionButton: colors.accent,
  testIdActionButton: '',
  titleColor: 'black',
};

export default memo(CrmSimpleModal);
