import React, { ReactElement, memo } from 'react';

import { IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import ArrowIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Trans } from '@tecma/i18n';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { useStore } from 'mobx/helpers';

import CrmButton from 'components/UI.Button.Base';

import CrmSvgLoader from './UI.SvgLoader';

import arrowBackIcon from 'images/assets/arrow-back.svg';

interface Props {
  additonalActions?: () => void;
  className?: string;
  content?: ReactElement;
}

const CrmGoBackButton = ({ additonalActions = () => {}, className = '', content = <Trans i18nKey={'general.back'} />, ...rest }: Props) => {
  const store = useStore();
  const history = useHistory();

  const goBack = () => {
    store.setUpdEvent(false);
    history.goBack();
    additonalActions();
  };

  const theme = useTheme();
  const matchesPhone = useMediaQuery(theme.breakpoints.down('xs'), {
    noSsr: true,
  });

  return (
    <>
      {!matchesPhone ? (
        <CrmButton onClick={goBack} className={className} {...rest} disableRipple={true}>
          <ArrowIosIcon style={{ transform: 'rotate(180deg)', width: '10px', height: '10px' }} fontSize='small' />
          &nbsp;{content}
        </CrmButton>
      ) : (
        <IconButton onClick={goBack} style={{ paddingTop: '0', paddingBottom: '0', paddingLeft: '0' }} disableRipple={true}>
          <CrmSvgLoader data={arrowBackIcon} alt='back' />
        </IconButton>
      )}
    </>
  );
};

export default memo(CrmGoBackButton);
