import { ReactNode } from 'react';

import { ErrorPage } from '@tecma/ds';
import i18n from '@tecma/i18n';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import { ENVIRONMENT } from '../constants/environment';
import { useStore } from '../mobx/helpers';

interface Props {
  children: ReactNode;
}
export const ErrorBoundary = ({ children }: Props) => {
  const store = useStore();

  return (
    // @ts-ignore
    <ReactErrorBoundary
      onError={(e) => console.error(e)}
      FallbackComponent={() => (
        <ErrorPage
          // @ts-ignore
          logo={
            store.loginProjectName !== undefined &&
            `${ENVIRONMENT.REACT_APP_BUCKET_BASEURL}/initiatives/${store.loginProjectName}/global/img/logo192.png`
          }
          language={store.getCurrentLanguage() ?? i18n?.language}
        />
      )}
    >
      {children}
    </ReactErrorBoundary>
  );
};
