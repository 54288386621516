import { createAsyncThunk } from '@reduxjs/toolkit';

import { createClientGraphQL } from '../../graphql/createClientGraphQL';
import { ResponseQueryAllClientsAdditionalInfo, QUERY_ALL_CLIENTS_ADDITIONAL_INFO } from '../../queries/list';
import { selectorProjectId } from '../selectors/general';

export const thunkAllClientsAdditionalInfo = createAsyncThunk<ResponseQueryAllClientsAdditionalInfo['getAllAdditionalInfoByProject'], void>(
  'otherInfo/list',
  async (_, thunkAPI) => {
    try {
      // @ts-ignore
      const project_id = selectorProjectId(thunkAPI.getState());
      const GraphQL = createClientGraphQL();
      const response = await GraphQL.query({ query: QUERY_ALL_CLIENTS_ADDITIONAL_INFO, variables: { project_id } });
      const { getAllAdditionalInfoByProject } = response.data as ResponseQueryAllClientsAdditionalInfo;
      if (getAllAdditionalInfoByProject) {
        const otherInfo = getAllAdditionalInfoByProject?.filter(
          (data) =>
            data.project_id === project_id &&
            data.tab === 'Altre Informazioni' &&
            ['radio', 'select', 'selectMultiple'].includes(data.type),
        );
        return thunkAPI.fulfillWithValue(otherInfo);
      }
      return thunkAPI.fulfillWithValue([]);
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue('Failed to load all clientsAdditionalInfo');
    }
  },
);
