import React, { memo } from 'react';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { observer } from 'mobx-react';

import { useStore } from 'mobx/helpers';

import CrmSnackbar from 'components/Snackbar';

const CrmSnackbarAmber = observer(() => {
  const store = useStore();
  return (
    <CrmSnackbar open={store.snackbarAmberOpen} onClose={() => store.setSnackbarAmber(false)}>
      <div className='snackbar-amber-body'>
        <InfoOutlinedIcon style={{ marginRight: '5px' }} />
        {store.snackbarMessage}
      </div>
    </CrmSnackbar>
  );
});

export default memo(CrmSnackbarAmber);
