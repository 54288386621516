import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ParamsOnSearch, ActiveFilters, DateRange } from '../../declarations/common';
import { createClientGraphQL } from '../../graphql/createClientGraphQL';
import { getValidFilters } from '../../helpers/get-valid-filters';
import { selectorProjectId } from '../selectors/general';

// #region ::: QUERY GRAPHQL :::
const query = gql`
  query getOtherInfoTab(
    $project_id: ID!
    $params: PaginatedClientsParams!
    $otherInfoFields: [OtherInfoProjectionFields]
    $filters: ClientsManagerFilters
  ) {
    getOtherInfoTab(project_id: $project_id, params: $params, filters: $filters, otherInfoFields: $otherInfoFields) {
      data
      paginationInfo {
        totalDocs
        totalPages
        page
        perPage
        prevPage
        nextPage
      }
    }
  }
`;
// #endregion

export const thunkClientListOtherInfo = createAsyncThunk<
  any,
  { params: ParamsOnSearch; filters: ActiveFilters; otherInfoFields: Array<{ path: string; name: string }>; dateRanges: DateRange[] }
>('other-info', async ({ params, filters, otherInfoFields, dateRanges }, thunkAPI) => {
  try {
    // @ts-ignore
    const project_id = selectorProjectId(thunkAPI.getState());
    const GraphQL = createClientGraphQL();
    const validFilters = getValidFilters(filters, dateRanges);
    const response = await GraphQL.query({
      query,
      variables: { project_id, params, otherInfoFields, ...(Object.keys(validFilters).length > 0 ? { filters: validFilters } : null) },
    });
    const { getOtherInfoTab } = response.data;
    return thunkAPI.fulfillWithValue(getOtherInfoTab);
  } catch (error) {
    return thunkAPI.rejectWithValue('Failed to load OtherInfo client list');
  }
});
