import AccountManager from 'images/assets/accountManager.svg';
import Appartamenti from 'images/assets/appartamenti.svg';
import CalendarIcon from 'images/assets/calendar.svg';
import Clients from 'images/assets/clients.svg';
import Dashboard from 'images/assets/dashboard.svg';
import GestioneInfoClientiIcon from 'images/assets/gestioneInfoClienti.svg';
import Preventivi from 'images/assets/ico_preventivi.svg';
import ProposalManager from 'images/assets/proposalManager.svg';

// #region ::: ROUTES
export const RouteClients_V2 = {
  key: 'clients',
  id: '/clienti',
  order: 2,
  exact: true,
  to: '/clienti',
  title: 'title.clientManager',
  navbarTitle: 'navbarTitle.clientManager',
  icon: Clients,
  navbar: false,
  goBack: false,
};

export const HomeRoute = {
  id: 'home',
  order: 0,
  exact: true,
  to: '/',
  title: 'title.home',
  navbarTitle: 'navbarTitle.home',
  icon: Dashboard,
  navbar: true,
  goBack: false,
};

export const DashboardRoute = {
  id: 'dashboard',
  order: 0,
  exact: true,
  to: '/dashboard',
  title: 'title.dashboard',
  navbarTitle: 'navbarTitle.dashboard',
  icon: Dashboard,
  navbar: false,
  goBack: true,
};

export const appartamentiRoute = {
  id: 'apps',
  order: 1,
  exact: true,
  to: '/appartamenti',
  title: 'title.apartments',
  navbarTitle: 'navbarTitle.apartments',
  icon: Appartamenti,
  navbar: true,
  goBack: false,
};

export const clientiRoute = {
  id: 'clients',
  order: 2,
  exact: true,
  to: '/clienti',
  title: 'title.clientManager',
  navbarTitle: 'navbarTitle.clientManager',
  icon: Clients,
  navbar: true,
  goBack: false,
};

export const GestioneUtentiRoute = {
  id: 'userInfoManager',
  order: 3,
  exact: true,
  to: '/userInfoManager',
  icon: GestioneInfoClientiIcon,
  title: 'title.userInfoManager',
  navbarTitle: 'navbarTitle.userInfoManager',
  navbar: true,
  goBack: false,
};

export const calendarRoute = {
  id: 'calendar',
  order: 5,
  exact: true,
  to: '/calendario',
  icon: CalendarIcon,
  title: 'title.calendar',
  navbarTitle: 'navbarTitle.calendar',
  navbar: true,
  goBack: false,
};

export const vendorRoute = {
  id: 'accountManager',
  order: 6,
  exact: true,
  to: '/accountManager',
  title: 'title.vendorManager',
  icon: AccountManager,
  navbarTitle: 'navbarTitle.vendorManager',
  navbar: true,
  goBack: false,
};

export const listaPreventiviRoute = {
  id: 'quoteList',
  order: 7,
  exact: true,
  to: '/preventivi',
  title: 'title.listaPreventivi',
  navbarTitle: 'navbarTitle.listaPreventivi',
  icon: Preventivi,
  navbar: true,
  goBack: false,
};

export const appDetailRoute = {
  id: 'appdet',
  order: 8,
  exact: true,
  breadcrumb: appartamentiRoute.title,
  to: '/scheda-appartamento/:id',
  title: 'title.apartmentCard',
  navbarTitle: 'navbarTitle.apartmentCard',
  navbar: false,
  goBack: true,
};

export const pertinenzaDetailRoute = {
  id: 'pertinenzadett',
  order: 17,
  exact: true,
  breadcrumb: appartamentiRoute.title,
  to: '/scheda-pertinenza/:id',
  title: 'title.pertinenzaCard',
  navbarTitle: 'navbarTitle.pertinenzaCard',
  navbar: false,
  goBack: true,
};

export const clientDetailRoute = {
  id: 'clidet',
  order: 9,
  exact: true,
  breadcrumb: clientiRoute.title,
  to: '/scheda-cliente/:id',
  title: 'title.clientCard',
  navbarTitle: 'navbarTitle.clientCard',
  navbar: false,
  goBack: true,
};

export const clientModifyRoute = {
  id: 'climod',
  order: 10,
  exact: true,
  breadcrumb: clientiRoute.title,
  to: '/modifica-cliente/:id',
  title: 'title.updateClientCard',
  navbarTitle: 'navbarTitle.updateClientCard',
  navbar: false,
  goBack: true,
};

export const clientAddRoute = {
  id: 'cliadd',
  order: 11,
  exact: true,
  breadcrumb: clientiRoute.title,
  to: '/aggiungi-cliente',
  title: 'title.addClient',
  navbarTitle: 'navbarTitle.addClient',
  navbar: false,
  goBack: true,
};

export const calendarDetailRoute = {
  id: 'detcal',
  order: 12,
  exact: true,
  to: '/dettagli-calendario/:id',
  breadcrumb: calendarRoute.title,
  title: 'title.appointmentDetails',
  navbarTitle: 'navbarTitle.appointmentDetails',
  navbar: false,
  goBack: true,
};

export const calendarNewDetailRoute = {
  id: 'detcal',
  order: 12,
  exact: true,
  to: '/new-event/:id',
  breadcrumb: calendarRoute.title,
  title: 'title.createAppointment',
  navbarTitle: 'navbarTitle.createAppointment',
  navbar: false,
  goBack: true,
};

export const calendarUpdateDetailRoute = {
  id: 'detcal',
  order: 12,
  exact: true,
  to: '/update-event/:id',
  breadcrumb: calendarRoute.title,
  title: 'title.updateAppointment',
  navbarTitle: 'navbarTitle.updateAppointment',
  navbar: false,
  goBack: true,
};

export const busySlotUpdateDetailRoute = {
  id: 'detcal',
  order: 13,
  exact: true,
  to: '/update-busy-slot/:id',
  breadcrumb: calendarRoute.title,
  title: 'title.updateSlot',
  navbarTitle: 'navbarTitle.updateSlot',
  navbar: false,
  goBack: true,
};

export const CreaAppartamentiRoute = {
  id: 'createApp',
  order: 15,
  exact: true,
  to: '/crea-appartamenti',
  title: 'title.createtList',
  navbarTitle: 'navbarTitle.createtList',
  navbar: false,
  goBack: true,
};

export const GestionePropRoute = {
  id: 'gestione-prop',
  order: 16,
  exact: true,
  to: '/gestioneProposta',
  title: 'title.gestioneProposta',
  icon: ProposalManager,
  navbarTitle: 'navbarTitle.gestioneProposta',
  navbar: true,
  goBack: false,
};

export const DocumentiHCRoute = {
  id: 'documenti-hc',
  order: 17,
  exact: true,
  to: '/documenti-hc',
  navbarTitle: 'navbarTitle.documentiHC',
  title: 'navbarTitle.documentiHC',
  icon: Preventivi,
  navbar: true,
  goBack: false,
};
// #endregion

const RouteConsts = [
  HomeRoute,
  DashboardRoute,
  appartamentiRoute,
  clientiRoute,
  calendarRoute,
  calendarDetailRoute,
  vendorRoute,
  listaPreventiviRoute,
  appDetailRoute,
  pertinenzaDetailRoute,
  clientDetailRoute,
  clientModifyRoute,
  clientAddRoute,
  CreaAppartamentiRoute,
  calendarUpdateDetailRoute,
  calendarNewDetailRoute,
  busySlotUpdateDetailRoute,
  GestioneUtentiRoute,
  GestionePropRoute,
  DocumentiHCRoute,
  RouteClients_V2,
];

export default RouteConsts;
