import React, { memo } from 'react';

import { ReactSVG } from 'react-svg';

interface Props {
  data?: string;
  className?: string;
  alt?: string;
  style?: object;
  onClick?: (event: any) => void;
  beforeInjection?: (svg: any) => void;
}

const CrmSvgLoader = ({ data, className = '', alt = 'image', style = {}, ...rest }: Props) => {
  if (data) return <ReactSVG style={style} alt={alt} src={data} className={className} {...rest} />;
  return null;
};

export default memo(CrmSvgLoader);
