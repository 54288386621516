import React, { CSSProperties, memo } from 'react';

import Button from '@material-ui/core/Button';
import 'styles/button.scss';

interface Props {
  children: React.ReactNode;
  extraClass?: string;
  onClick: () => void;
  color?: 'default' | 'primary' | 'secondary';
  disableRipple?: boolean;
  className?: string;
  disabled?: boolean;
  style?: CSSProperties;
  id?: string;
}

const CrmButton = ({ children, extraClass = '', onClick, color = 'primary', ...rest }: Props) => (
  <Button onClick={onClick} className={`${extraClass}`} variant='contained' color={color} {...rest}>
    {children}
  </Button>
);

export default memo(CrmButton);
