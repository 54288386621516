import { ReactNode } from 'react';

import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import { IS_DEVELOPMENT } from '../constants/activePromos';
import { utilityGetBaseName } from '../helpers/functions/get-base-name';
import { useStore } from '../mobx/helpers';
import { useAppSelector } from '../redux/hooks';
import { sliceEnvironment } from '../redux/slice/slice.environment';

interface Props {
  children: ReactNode;
}

export const ProviderGraphQL = ({ children }: Props) => {
  const store = useStore();
  const environment = useAppSelector(sliceEnvironment.selectors.environment);

  const createClient = () => {
    const errorLink = onError(({ networkError, graphQLErrors, operation, response }) => {
      // @ts-ignore
      if (
        // @ts-ignore
        networkError?.result?.errors[0]?.extensions?.code === 'UNAUTHENTICATED' ||
        // @ts-ignore
        networkError?.statusCode === 401 ||
        graphQLErrors?.[0]?.extensions?.response?.status === 403
      ) {
        // @ts-ignore
        console.log(`Apollo client network error code: ${networkError?.statusCode}`);
        store.forceLogout(true); // prima passa per il componente "LogoutListener" per la pulizia dei cookies e poi avverrà il reindirizzamento
        // @ts-ignore
      } else if (operation?.operationName === 'getUserByJWT' && response?.data?.getUserByJWT === null && response?.errors?.length > 0) {
        console.log(`getUserByJWT: user not found`);
        store.forceLogout(true); // prima passa per il componente "LogoutListener" per la pulizia dei cookies e poi avverrà il reindirizzamento
      }
    });
    const httpLink = from([
      errorLink,
      new HttpLink({
        credentials: 'include',
        uri: IS_DEVELOPMENT ? environment.REACT_APP_APOLLO_URI : `${utilityGetBaseName()}graphql`,
      }),
    ]);

    const authLink = setContext((_, { headers }) => ({ headers }));

    return new ApolloClient({
      link: authLink.concat(httpLink),
      credentials: 'include',
      cache: new InMemoryCache({
        addTypename: false,
      }),
    });
  };

  return <ApolloProvider client={createClient()}>{children}</ApolloProvider>;
};
