import { useState } from 'react';

import { useQuery } from '@apollo/client';
import i18n, { useTranslation } from '@tecma/i18n';

import UserService from 'api/user';
import Client from 'helpers/queries/query.client';
import { useStore } from 'mobx/helpers';

import { ENVIRONMENT } from '../constants/environment';

const useNewsletter = () => {
  const store = useStore();
  const userService = new UserService(store.baseUrl);
  const { t } = useTranslation();
  // @ts-ignore
  const userNewsletterConsents = store.loggedUser?.consents?.newsletter;

  const [isLoading, setIsLoading] = useState(false);
  const [subscription, setSubscription] = useState(userNewsletterConsents?.subscription);
  const [privacyPolicy, setPrivacyPolicy] = useState(userNewsletterConsents?.privacyPolicy);
  const [showErrorPrivacy, setShowErrorPrivacy] = useState(false);

  const loadUserInfo = useQuery(Client.GET_USER_INFO, {
    // @ts-ignore
    ...Client.GET_USER_INFO_DEFAULT_OPTIONS(store.projectId),
    skip: true,
  });

  const isSubscriptionNotChosen = typeof subscription !== 'boolean';
  const isPrivacyNotChosen = typeof privacyPolicy !== 'boolean';

  const getIsDisabledPrivacy = () => {
    return isSubscriptionNotChosen || subscription === false || userNewsletterConsents?.privacyPolicy;
  };

  const getIsDisabledSave = () => {
    if (isSubscriptionNotChosen && isPrivacyNotChosen) return true;
    return !!(subscription === userNewsletterConsents?.subscription && privacyPolicy === userNewsletterConsents?.privacyPolicy);
  };

  const needResetShowError = () => {
    if (showErrorPrivacy) {
      setShowErrorPrivacy(false);
    }
  };

  const handleOnChangeSubscription = (_, val) => {
    if (isLoading) return;
    if (val === 'false' && !userNewsletterConsents?.privacyPolicy) {
      setPrivacyPolicy(false);
    }
    needResetShowError();
    setSubscription(val === 'true');
  };

  const handleOnChangePrivacy = () => {
    if (isLoading) return;
    needResetShowError();
    setPrivacyPolicy((curr) => !curr);
  };

  const handleOnConfirmNewsletter = async () => {
    if (isLoading) return;
    if (subscription && !privacyPolicy) {
      setShowErrorPrivacy(true);
      return;
    }
    setIsLoading(true);
    try {
      // @ts-ignore
      const response = await userService.subscribeToNewsletterApi(store?.loggedUser?.id, store?.projectId, {
        projectHostKey: store.hostKey,
        privacyPolicy,
        projectDefaultLang: store.defaultLang ?? 'en-GB',
        subscription,
      });
      if (response.ok) {
        const response = await loadUserInfo.refetch();
        store.setSnackbar(true, t('snackbar.successOperation'));
        store.setLoggedUser(response.data.getUserByJWT);
        store.setOpenNewsletterModal(false);
      } else {
        // @ts-ignore
        store.setSnackbarError(true, `${t('snackbar.failedOperation')}, ${response?.message}`);
      }
    } catch (e) {
      console.error(e);
      // @ts-ignore
      store.setSnackbarError(true, `${t('snackbar.failedOperation')}, ${e?.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnClickCancel = () => {
    setSubscription(userNewsletterConsents?.subscription);
    setPrivacyPolicy(userNewsletterConsents?.privacyPolicy);
    setShowErrorPrivacy(false);
    store.setOpenNewsletterModal(false);
  };

  const getPrivacyDocumentHref = () => {
    const currentLanguage = i18n.language.split('-')?.[0] !== 'it' ? 'en' : 'it';
    const documentUrl = `${ENVIRONMENT.REACT_APP_BUCKET_BASEURL}/businessplatform/pdf/newsletterPrivacyPolicy/${currentLanguage}/Privacy Policy Newsletter.pdf`;
    return documentUrl;
  };

  return {
    isOpenModal: store.openNewsletterModal,
    subscriptionValue: subscription,
    privacyPolicyValue: privacyPolicy,
    showErrorPrivacy,
    isLoading,
    isDisabledConfirmAction: getIsDisabledSave(),
    isDisabledPrivacy: getIsDisabledPrivacy(),
    privacyDocumentHref: getPrivacyDocumentHref(),
    onClickCancelAction: handleOnClickCancel,
    onClickConfirmAction: handleOnConfirmNewsletter,
    onChangeSubscription: handleOnChangeSubscription,
    onChangePrivacy: handleOnChangePrivacy,
  };
};

export default useNewsletter;
