import { createAsyncThunk } from '@reduxjs/toolkit';

import { createClientGraphQL } from '../../graphql/createClientGraphQL';
import { ResponseQueryActionDetail, QUERY_ACTION_DETAIL } from '../../queries/list';
import { selectorProjectId } from '../selectors/general';

export const thunkActionDetail = createAsyncThunk<ResponseQueryActionDetail['getActionDetail'], string>(
  'action/detail',
  async (actionId, thunkAPI) => {
    try {
      // @ts-ignore
      const projectId = selectorProjectId(thunkAPI.getState());
      const GraphQL = createClientGraphQL();
      const response = await GraphQL.query({
        query: QUERY_ACTION_DETAIL,
        variables: { projectId, actionId },
      });
      const { getActionDetail } = response.data as ResponseQueryActionDetail;
      return thunkAPI.fulfillWithValue(getActionDetail);
    } catch (error) {
      return thunkAPI.rejectWithValue('Failed to load action details');
    }
  },
);
