import { PERMISSION, PermissionKey } from '../constants/PagePermission';
import { useStore } from '../mobx/helpers';

import AccountManager from 'images/assets/accountManager.svg';
import Appartamenti from 'images/assets/appartamenti.svg';
import CalendarIcon from 'images/assets/calendar.svg';
import Clients from 'images/assets/clients.svg';
import Dashboard from 'images/assets/dashboard.svg';
import GestioneInfoClientiIcon from 'images/assets/gestioneInfoClienti.svg';
import Preventivi from 'images/assets/ico_preventivi.svg';
import ProposalManager from 'images/assets/proposalManager.svg';

// #region ::: OPTIONS
export const options: Array<{ key: PermissionKey; url: string; icon: string; label: string }> = [
  {
    key: 'home',
    url: '/',
    icon: Dashboard,
    label: 'navbarTitle.home',
  },
  {
    key: 'apps',
    url: '/appartamenti',
    icon: Appartamenti,
    label: 'navbarTitle.apartments',
  },
  {
    key: 'clients',
    url: '/clienti',
    icon: Clients,
    label: 'navbarTitle.clientManager',
  },

  {
    key: 'userInfoManager',
    url: '/userInfoManager',
    icon: GestioneInfoClientiIcon,
    label: 'navbarTitle.userInfoManager',
  },
  {
    key: 'calendar',
    url: '/calendario',
    icon: CalendarIcon,
    label: 'navbarTitle.calendar',
  },
  {
    key: 'accountManager',
    url: '/accountManager',
    icon: AccountManager,
    label: 'navbarTitle.vendorManager',
  },
  {
    key: 'quoteList',
    url: '/preventivi',
    icon: Preventivi,
    label: 'navbarTitle.listaPreventivi',
  },
  {
    key: 'gestione-prop',
    url: '/gestioneProposta',
    icon: ProposalManager,
    label: 'navbarTitle.gestioneProposta',
  },
  {
    key: 'documenti-hc',
    url: '/documenti-hc',
    icon: Preventivi,
    label: 'navbarTitle.documentiHC',
  },
];
// #endregion

export const useNavbarRoutes = () => {
  const store = useStore();
  const navbarRoutes = options.filter((e) => {
    const page = PERMISSION[e.key];
    const hasPermission = !page || page.includes(store.loggedUser?.role);
    return hasPermission;
  });
  return navbarRoutes;
};
