import { createAsyncThunk } from '@reduxjs/toolkit';

import { createClientGraphQL } from '../../graphql/createClientGraphQL';
import { ResponseQueryAdditionalInfo, QUERY_CLIENT_ADDITIONAL_INFO_BY_NAME } from '../../queries/list';
import { selectorProjectId } from '../selectors/general';

export const thunkProjectActions = createAsyncThunk<ResponseQueryAdditionalInfo['getAdditionalInfoByName'], void>(
  'actions/list',
  async (_, thunkAPI) => {
    try {
      // @ts-ignore
      const project_id = selectorProjectId(thunkAPI.getState());
      const GraphQL = createClientGraphQL();
      const response = await GraphQL.query({ query: QUERY_CLIENT_ADDITIONAL_INFO_BY_NAME, variables: { project_id, name: 'actions' } });
      const { getAdditionalInfoByName } = response.data as ResponseQueryAdditionalInfo;
      return thunkAPI.fulfillWithValue(getAdditionalInfoByName);
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue('Failed to load project actions');
    }
  },
);
