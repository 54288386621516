import { createSlice } from '@reduxjs/toolkit';

import { thunkEnvironment } from '../thunks/thunk.environment';

interface Environment {
  SKIP_PREFLIGHT_CHECK: string;
  HTTPS: string;
  REACT_APP_CALENDAR_API_BASE_URL: string;
  REACT_APP_HOST: string;
  REACT_APP_TRANSLATION_URL: string;
  REACT_APP_TIMEOUT_TOKEN: string;
  REACT_APP_CIRO_LOCK_ENDPOINT: string;
  REACT_APP_CIRO_LOCK_ENDPOINT_KEY: string;
  REACT_APP_DATADOG_APPLICATION_ID: string;
  REACT_APP_DATADOG_CLIENT_TOKEN: string;
  REACT_APP_BUCKET_BASEURL: string;
  REACT_APP_APOLLO_URI: string;
  REACT_APP_USER_API_BASE_URL: string;
  REACT_APP_NAMESPACE: string;
}

interface State extends Environment {
  loading?: boolean;
}

export const sliceEnvironment = createSlice({
  initialState: {} as State,
  name: 'environment',
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(thunkEnvironment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(thunkEnvironment.fulfilled, (state, action) => {
      return action.payload;
    });
  },
  selectors: {
    environment: (state) => state,
    isLoading: (state) => state.loading || false,
  },
});
