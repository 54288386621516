import { createAsyncThunk } from '@reduxjs/toolkit';

import { getValidFilters } from '@module-clients/helpers/get-valid-filters';

import { ParamsOnSearch, ActiveFilters, DateRange } from '../../declarations/common';
import { createClientGraphQL } from '../../graphql/createClientGraphQL';
import { ResponseQueryClientListMyHome, QUERY_CLIENT_LIST_MYHOME } from '../../queries/list';

export const thunkClientListMyHome = createAsyncThunk<
  ResponseQueryClientListMyHome['getMyHomeTabInfo'],
  { project_id: string; params: ParamsOnSearch; filters: ActiveFilters; dateRanges: DateRange[] }
>('clientMyHome/list', async ({ project_id, params, filters, dateRanges }, thunkAPI) => {
  try {
    const GraphQL = createClientGraphQL();
    const validFilters = getValidFilters(filters, dateRanges);
    const response = await GraphQL.query({
      query: QUERY_CLIENT_LIST_MYHOME,
      variables: { project_id, params, ...(Object.keys(validFilters).length > 0 ? { filters: validFilters } : null) },
    });
    const { getMyHomeTabInfo } = response.data as ResponseQueryClientListMyHome;
    return thunkAPI.fulfillWithValue(getMyHomeTabInfo);
  } catch (error) {
    console.error(error);
    return thunkAPI.rejectWithValue('Failed to load MyHome client list');
  }
});
