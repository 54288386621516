import { createSlice } from '@reduxjs/toolkit';

import { thunkI18n } from '../thunks/thunk.i18n';

export const sliceI18n = createSlice({
  name: 'i18n',
  initialState: null as any,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(thunkI18n.fulfilled, (state, action) => action.payload);
  },
  selectors: {
    loaded: (state) => state !== null,
  },
});
