import { ReactNode, useEffect } from 'react';

import CrmLoader from 'components/UI.Loader';

import { ENVIRONMENT } from '../constants/environment';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { sliceI18n } from '../redux/slice/slice.i18n';
import { thunkI18n } from '../redux/thunks/thunk.i18n';

interface Props {
  children: ReactNode;
}

export const ProviderI18n = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const i18nLoaded = useAppSelector(sliceI18n.selectors.loaded);

  useEffect(() => {
    dispatch(thunkI18n(ENVIRONMENT.REACT_APP_BUCKET_BASEURL));
  }, []);

  if (!i18nLoaded) return <CrmLoader hasBackdrop={false} z loading />;

  return <>{children}</>;
};
